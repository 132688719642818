import React, { useState} from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Steps } from 'antd';
import { useEffect } from 'react';
import { createCookie, readCookie } from '../../../../cookie';
import { useReducer } from 'react';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import Step3Cause from './Step3Cause';
import Step2Skills from './Step2Skills';
import Step1ProfileInfo from './Step1ProfileInfo';
import configs from '../../../../config/urlConfigs';


const EmployeeDetailsAndPreferenceModal = ({ show, close, editMode, firstLogin, }) => {
    const [current, setCurrent] = useState(0);
    const [user, setUser] = useState(null);
    const [mobile, setMobile] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [alternateMobileNumber, setAlternateMobileNumber] = useState('');
    const [profile, setProfile] = useState(null);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedCause, setSelectedCause] = useState([]);
    const [languages, setLanguages] = useState([{}]);
    const [uploadingProfile, setUploadingProfile] = useState(false);
    const [checked, setChecked] = useState(true);
    const [ignore, forceUpdate] = useReducer((x) => x + 1, 0);
   const [ croppedImage, setCroppedImage] = useState(null);
   const [Checkbox_Profile,setCheckbox_Profile] = useState(null);
    

    const { Step } = Steps;

    const onChange = val => {
        setCurrent(val);
    };

    const getUserData = () => {
        // const userData = JSON.parse(readCookie('userData'));
        // setUser(userData);
        let endPoint =
            // this.props.member ? `/get-user-data/${this.props.member}` :
                "/get-user-data"
        fetch(configs.BACKEND_API_URL + endPoint, {
            method: "get",
            headers: {
                Auth: JSON.parse(readCookie("access_token")).access_token,
            },
        })
            .then((data) => data.json())
            .then((data) => {
                
                let mobileNo = data.data.mobileNo;
                if (!mobileNo || mobileNo === "false") mobileNo = "";
                if (data.data.preference) {
                    setSelectedSkills(data.data.preference.skills);
                    setSelectedCause(data.data.preference.cause);
                    
                }
                
            });
    }

    useEffect(getUserData, []);
    useEffect(() => {
        const userData = JSON.parse(readCookie('userData'));
        setUser(userData);
        userData.profilePicture && setProfile(userData.profilePicture);
        userData.mobileNo && setMobile(userData.mobileNo);
        userData.whatsapp && setWhatsapp(userData.whatsapp);
        userData.alternateMobileNumber && setAlternateMobileNumber(userData.alternateMobileNumber);
        userData.receiveNotifications && setChecked(userData.receiveNotifications);
        // if (editMode
        //     && userData.preference
        // ) {
        //     // setSelectedSkills(userData.preference.skills);
        //     // setSelectedCause(userData.preference.cause);
        //     // if (userData.preference.languagesKnown && userData.preference.languagesKnown.length > 0) {
        //     //     setLanguages(userData.preference.languagesKnown);
        //     // }
        //     // getUserData();
        // }
        // getUserData();

    }, [editMode]);
    const notifyError = (text) => toast.error(text);
    const handleBasic = (val) => {
        if (val.target.id === 'whatsapp' ) {
            if(val.target.value.length < 11 ) setWhatsapp(val.target.value);
            else notifyError("Only 10 digit mobile numbers are allowed.")
        }
        if (val.target.id === 'alternateMobileNumber') {
            if (val.target.value.length < 11) setAlternateMobileNumber(val.target.value);
            else notifyError("Only 10 digit mobile numbers are allowed.")
        }
        if (val.target.id === 'mobile') {
             if(val.target.value.length < 11 ) setMobile(val.target.value);
             else notifyError("Only 10 digit mobile numbers are allowed.")
        }
        if (val.target.id === 'profile') {
            setProfile(val.target)
        }
    }

    const upload = async (e) => {
        let data = new FormData();
        data.append("image", croppedImage);
        // let data = new FormData();
        // data.append("image", e.target.files[0]);
        setUploadingProfile(true);

        
        fetch(configs.BACKEND_API_URL + "/uploadImage", {
            method: "POST",
            headers: {
                Auth: JSON.parse(readCookie("access_token")).access_token,
            },
            body: data,
        })
            .then((data) => data.json())
            .then(
                function (data) {
                    if (data && data.url) {
                        setProfile(data.url);
                        setUploadingProfile(false);
                    }
                }.bind(this)
        ).catch(err => { notifyError("Unsupported file format detected!"); e.target.value = null; });
    };

    const handleSelectSkills = (val) => {
        const arr = selectedSkills;
        if (arr.includes(val)) {
            arr.splice(arr.indexOf(val), 1);
        } else arr.push(val);
        setSelectedSkills(arr);
        forceUpdate();
    }

    const handleSelectCause = (val) => {
        const arr = selectedCause;
        if (arr.includes(val)) {
            arr.splice(arr.indexOf(val), 1);
        } else arr.push(val);
        setSelectedCause(arr);
        forceUpdate();
    }

    const handleLangugage = (index, key, value) => {
        var arr = languages;
        arr[index][key] = value;
        setLanguages(arr);
        forceUpdate();
    }

    const addLanguage = () => {
        var arr = languages;
        arr.push({});
        setLanguages(arr);
        forceUpdate();
    }

    const removeLang = (index) => {
        var arr = languages;
        arr.splice(index, 1);
        setLanguages(arr);
        forceUpdate();
    }

    const handleSubmit = () => {
        let data = {};
        data.preference = {
            skills: selectedSkills,
            cause: selectedCause,
            languagesKnown: languages.filter(x => x.speak || x.write || x.read),
        };
        data.mobileNo = mobile;
        data.whatsapp = whatsapp;
        data.alternateMobileNumber = alternateMobileNumber;
        // console.log("profile ====>>>>>  ",profile);
        data.profilePicture = profile;
        data.receiveNotifications = checked;
        fetch(configs.BACKEND_API_URL + "/save-user-data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Auth: JSON.parse(readCookie('access_token')).access_token,
            },
            body: JSON.stringify(data),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                let userData = JSON.parse(readCookie("userData"));
                userData.preference = {
                    skills: selectedSkills,
                    cause: selectedCause,
                    languagesKnown: languages
                };
                userData.mobileNo = mobile;
                userData.whatsapp = whatsapp;
                userData.alternateMobileNumber = alternateMobileNumber;
                userData.profilePicture = profile;
                userData.receiveNotifications = checked;
                if (!userData.consentLastUpdated || userData.consentLastUpdated !== checked) {
                    userData.consentLastUpdated = new Date();
                }
                // console.log("JSON.stringify(userData)",JSON.stringify(userData));
                // trim userdata cookie size
                delete userData.organisationData;
                delete userData.questionnairePreferences;
                delete userData.preference;
                
                createCookie("userData", JSON.stringify(userData), 7);
                if (response.status === "ok") {
                    if (!firstLogin) {
                        swal("Thank you for updating your details/preferences", "", "success").then(
                            function () {
                                window.location.reload();
                            }
                        );
                    } else if (firstLogin) {
                        swal("Thank you for updating your details/preferences", "", "success").then(
                            function () {
                                // Todo: add logic here to redirect to resumption_page, if present; then clear cookie -- if firstLogin
                                const RESUMPTION_URL = readCookie('RESUMPTION_URL') === '/' ? '' : readCookie('RESUMPTION_URL');
                                if (RESUMPTION_URL) {
                                    eraseCookie('RESUMPTION_URL');
                                    window.location = RESUMPTION_URL;
                                } else {
                                    window.location.reload();
                                }
                            }
                        );

                    }
                    close();
                } else {
                    swal({
                        type: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                    }).then(function () {
                        window.location.reload();
                    });
                }
            });
    };



    const handleNext = (action) => {
        if (mobile.length >= 10) {
            if (action) {
                if (firstLogin) {
                    if (profile !== '' && Checkbox_Profile) {
                        upload().then(() => {
                            console.log('handling upload...');
                            handleSubmit();
                        });
                    } else {
                        handleSubmit();
                    }
                } else {
                    setCurrent(current + 1);
                    if (profile !== '' && Checkbox_Profile) {
                        upload();
                    }
                }
            } else return true;
        }
    }
    const handleNext1 = (action) => {
        if (current === 2 && selectedSkills.length > 0) {
            if (action) {
                handleSubmit();
            } else return true;
        } else if (current === 1 && selectedCause.length > 0) {
            if (action) {
                setCurrent(current + 1);
                if(profile !== '' && Checkbox_Profile){
                    upload();
                }
            } else return true;
        } else if (current === 0 && mobile.length >= 10) {
            if (action) {
                if (firstLogin) {
                    if (profile !== '' && Checkbox_Profile) {
                        upload().then(() => {
                            console.log('handling upload...');
                            handleSubmit();
                        });
                    } else {
                        handleSubmit();
                    }
                    // swal({
                    //     text: "We're interested in knowing the causes you'd like to support and the skills you can contribute to an NGO.",
                    //     // icon: 'info',
                    //     // buttons: ["No", "Yes"],
                    //     buttons: {
                    //         cancel: {
                    //             text: "I'll do it later",
                    //             value: null,
                    //             visible: true,
                    //             className: "btn btn-info",
                    //             closeModal: true,
                    //         },
                    //         confirm: {
                    //             text: "Continue",
                    //             value: true,
                    //             visible: true,
                    //             className: "btn btn-success",
                    //             closeModal: true
                    //         }
                    //     }
                    // }).then(function (result) {
                    //     if (result) {
                    //         // proceeding with step 2 (skills) & 3 (preferences)
                    //         setCurrent(current + 1);
                    //         if (profile !== '' && Checkbox_Profile) {
                    //             upload();
                    //         }     
                    //     } else {
                    //         // skip selecting preferences and skills, if the user chooses to do so
                    //         if (profile !== '' && Checkbox_Profile) {
                    //             upload().then(() => {
                    //                 console.log('handling upload...');
                    //                 handleSubmit();
                    //             });
                    //         } else {
                    //             handleSubmit();
                    //         }
                    //     }
                    // });
                } else {
                    setCurrent(current + 1);
                    if (profile !== '' && Checkbox_Profile) {
                        upload();
                    }
                }
            } else return true;
        }
    }

    const subdomain = configs.subdomain;
    
    function dataURItoBlob(dataURI) {
        var binary = atob(dataURI.split(',')[1]);
         var array = [];
        for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
        }

         return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
        }


    return (
        <Modal
            show={show}
            onHide={editMode ? close : null}
            size="lg"
            bsSize='lg'
            scrollable={true}
        >
            <Modal.Header closeButton={editMode ? true : false}>
                <Modal.Title className='ee-page-title' style={{ letterSpacing: 0 }}>About Me</Modal.Title>
                {/** // Todo - IBL Volunteering week change  */}
                {/* <Modal.Title className='ee-page-title' style={{ letterSpacing: 0 }}>My Preferences</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <div style={{ margin: 20 }} className='hideScroll'>
                    {/** // Todo - IBL Volunteering week change  */}
                    {/* <Steps current={current} onChange={(val) => onChange(val)}>
                        <Step title="About Me" description="Contact details" />
                        <Step title="Causes" description="I would like to support" />
                        <Step title="Skills" description="I can contribute" />
                    </Steps> */}

                    <div style={{ marginTop: 20, height: 550 }}>
                        {user && current == 0 &&
                            <Step1ProfileInfo
                                user={user}
                                profile={profile}
                                mobile={mobile}
                            editMode={editMode}
                            alternateMobileNumber={alternateMobileNumber}
                                whatsapp={whatsapp}
                                checked={checked}
                                uploadingProfile={uploadingProfile}
                                onUpload={upload}
                            imageData={(data)=>{setCroppedImage(data)}}
                                handleBasic={handleBasic}
                                showCheckboxProfilePicture={(data)=>{setCheckbox_Profile(data)}}
                                handleCheck={val => setChecked(val)}
                            />}
                        {user && current == 1 &&
                            <div className='modalScroll' >
                                <Step3Cause
                                    selectedCause={selectedCause}
                                    handleSelectCause={handleSelectCause}
                                    subdomain={subdomain.toLowerCase()}
                                />
                            </div>
                        }
                        {user && current == 2 &&
                            <div className='modalScroll' >
                                <Step2Skills
                                    selectedSkills={selectedSkills}
                                    handleSelectSkills={handleSelectSkills}
                                    subdomain={subdomain.toLowerCase()}
                                    handleLangugage={handleLangugage}
                                    languages={languages}
                                    removeLang={removeLang}
                                    addLanguage={addLanguage}
                                />
                            </div>}

                        {current ? <button
                            onClick={() => setCurrent(current - 1)}
                            type="submit" class="btn pref-modal-btn"
                            style={{ left: 10, color: 'white' }}
                        >
                            {'<< Prev'}
                        </button> : null}
                        <button
                            onClick={() => handleNext('action')}
                            type="submit" class="btn pref-modal-btn"
                            style={{ right: 10, color: 'white', backgroundColor: !handleNext() ? 'lightgray' : null }}
                        >
                            {firstLogin? 'Finish' : current < 2 ? 'Next >>' : 'Submit'}

                            {/* {'Finish'} */}
                            {/** // Todo - IBL Volunteering week change  */}
                            {/* {current < 2 ? 'Next >>' : 'Submit'} */}
                        </button>
                    </div>
                </div>
            </Modal.Body>
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={true}
                closeOnClick={false}
                newestOnTop={false}
                pauseOnHover={true}
            />
        </Modal >
    )
}

export default EmployeeDetailsAndPreferenceModal