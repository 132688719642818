import React, { Component } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

const checkValue = require('../../../../../checkValue.js').checkValue;
const readCookie = require('../../../../../cookie.js').readCookie;
import { ToastContainer, toast } from "react-toastify";
import configs from '../../../../../config/urlConfigs.js';
export default class SingleOpportunityApplyModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isTransportRequired: false,
			sizeOfTee: '',
			noOfPeopleAlong: '',
			conditionsAccepted: false,
			allowedRegistrations: 0,
			displayRegistrations: false,
			friends: [{ id: 0 }],
			initialFriends: 0,
			isResponse :true,
			userId : JSON.parse(readCookie('userData')).id,
			
		}
		this.submitApply = this.submitApply.bind(this);
		this.isTransportRequiredChange = this.isTransportRequiredChange.bind(this);
		this.sizeOfTeeSelect = this.sizeOfTeeSelect.bind(this);
		this.noOfPeopleAlongChange = this.noOfPeopleAlongChange.bind(this);
		this.conditionsAcceptedChange = this.conditionsAcceptedChange.bind(this);
		this.addFriends = this.addFriends.bind(this);
		this.deleterow = this.deleterow.bind(this);
		this.handleAddFriends = this.handleAddFriends.bind(this);
		this.getFriendsList = this.getFriendsList.bind(this);
	}

	componentDidMount() {
		if (parseInt(this.props.volunteerOpportunity.registrationsAllowed) > 0) {
			if (this.props.volunteerOpportunity.volunteersRequired - (this.props.volunteerOpportunity.attendees.length + this.props.volunteerOpportunity.friends.length) > parseInt(this.props.volunteerOpportunity.registrationsAllowed)) {
				this.setState({ allowedRegistrations: parseInt(this.props.volunteerOpportunity.registrationsAllowed) })
			} else {
				this.setState({ allowedRegistrations: this.props.volunteerOpportunity.volunteersRequired - (this.props.volunteerOpportunity.attendees.length + this.props.volunteerOpportunity.friends.length)  })
			}
		}
		this.getFriendsList();
	}

	submitApply() {
		this.setState({ isResponse : false })
		let data = {}
		data['isTransportRequired'] = this.state.isTransportRequired;
		data['sizeOfTee'] = this.state.sizeOfTee;
		data['noOfPeopleAlong'] = this.state.noOfPeopleAlong;
		data['conditionsAccepted'] = this.state.conditionsAccepted;
		data['guestAccepted']= this.state.displayRegistrations;
		if (this.friendsDetailsAdded() && this.state.displayRegistrations) {
			data['friends'] = this.state.friends;
		}
		if(this.props.volunteerOpportunity.isUserAttendee){
			fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.props.volunteerOpportunity.id + '/update', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function (response) {
				return response.json();
			}).then(function (data) { 
				if(data.status === false && data.isRegistrationFull){
					swal({
						title: data.message,
						type: "error",
						confirmButtonText: "Ok",
					}).then((result) => {
						if (result) {
							this.props.closeReserveModal();
							window.location.reload()
						}
					}).catch(() => { });
		
				   }
				if (data.status === 'ok') {
					this.props.showAddToCalendarModal();
					let path = window.location.pathname;
					path = path.split('/').pop();
					fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + path, {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then((data) => data.json())
						.then(function (data) {
							if (data.hasOwnProperty('data')) {
								for (let i = 0; i < data.data.length; i++) {
									data.data.comments[i]['showReply'] = false;
								}
								this.props.updateSelectedOpportunity(data['data']); 
								this.props.fetchOpportunityDetails();
							} else {
							}
						}.bind(this));
					this.props.closeReserveModal();
				}
			}.bind(this))

		}
		else {
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.props.volunteerOpportunity.id + '/apply', {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(function (response) {
			return response.json();
		}).then(function (data) { 
           if(data.status === false && data.isRegistrationFull){
			swal({
				title: data.message,
				type: "error",
				confirmButtonText: "Ok",
			}).then((result) => {
				if (result) {
					this.props.closeReserveModal();
					window.location.reload()
				}
			}).catch(() => { });

		   }
			if (data.status === 'ok') {
				this.props.showAddToCalendarModal();
				let path = window.location.pathname;
				path = path.split('/').pop();
				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + path, {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
					.then(function (data) {
						if (data.hasOwnProperty('data')) {
							for (let i = 0; i < data.data.length; i++) {
								data.data.comments[i]['showReply'] = false;
							}
							this.props.updateSelectedOpportunity(data['data']);
							this.props.fetchOpportunityDetails();
						} else {
						}
					}.bind(this));
				this.props.closeReserveModal();
			} else if (data.status ==='error' && data.message) {
				this.props.notifyError(data.message);
			}
		}.bind(this))
	}
	}

	isTransportRequiredChange(e) {
		this.setState({
			isTransportRequired: e.target.checked
		})
	}

	sizeOfTeeSelect(e) {
		this.setState({
			sizeOfTee: e.target.value
		})
	}

	noOfPeopleAlongChange(e) {
		this.setState({
			noOfPeopleAlong: e.target.value
		})
	}

	conditionsAcceptedChange(e) {
		this.setState({
			conditionsAccepted: e.target.checked 
		})
	}

	friendsDetailsAdded() {
		const index = this.state.friends.length - 1;
		if (this.state.friends[index].name && this.state.friends[index].gender && this.state.friends[index].age) {
			return true
		} else return false
	}

	addFriends() {
		if (this.friendsDetailsAdded()) {
			var addRow = [];
			addRow = this.state.friends;
			addRow.push({ id: this.state.friends.length });
			this.setState({ friends: addRow });
		}
	}

	deleterow(id, e) {
		if(!e){
				if ( id === 0  ){
							swal({
								title: "Are you sure you want to remove this guest? ",
								type: "warning",
								showCancelButton: true,
								confirmButtonText: "Yes",
								cancelButtonText: "Cancel",
							}).then((result) => {
								if (result) {
									this.setState({ displayRegistrations: false, friends: [{ id: 0 }] })
								}
							}).catch(() => { });
			}
			else {

				swal({
					title: "Are you sure you want to remove this guest? ",
					type: "warning",
					showCancelButton: true,
					confirmButtonText: "Yes",
					cancelButtonText: "Cancel",
				}).then((result) => {
					if (result) {
						// this.setState({ displayRegistrations: false, friends: [{ id: 0 }] })
						var delrow = [{ id: 0 }];
						delrow = this.state.friends.length > 0 ? this.state.friends  : [{ id: 0 }] ;
						delrow.splice(id, 1)
						this.setState({ friends: delrow })
			   }
				}).catch(() => { });
				
			}
				
		}
		else if (e){
			swal({
				title: "Are you sure you want to remove this guest? ",
				            			// text: "Are you sure you want to remove this guest",
				            type: "warning",
				            showCancelButton: true,
				            confirmButtonText: "Yes",
				            cancelButtonText: "Cancel",
				        }).then((result) => {
				            if (result) {
				                fetch(configs.BACKEND_API_URL + '/ee/remove-friend/' + e,
				                    {
				                        method: "PUT",
				                        headers: {
				                            'Content-Type': 'application/json',
				                            'Auth': JSON.parse(readCookie('access_token')).access_token
				                        },
				                    }).then(function (response) {
				                        return response.json();
				                    }).then(function () {
									// if(this.state.friend )
				                        this.getFriendsList();
				                    }.bind(this))
				            }
				        }).catch(() => { });

		}

	}
	

	handleAddFriends(index, value, key) {
		let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`);

		if (commentRegex.test(value) === true || true){
		if (value) {
			var friends = this.state.friends;
			friends[index][key] = value
			this.setState({ friends });
		}
	}
		else {
			this.notifyError("Only letters, numbers and special characters are allowed");
		}
	}
	notifyError = (text) => toast.error(text);


	 getFriendsList () {
		

        fetch(configs.BACKEND_API_URL + '/ee/friends-list/' + this.props.volunteerOpportunity.id,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Auth': JSON.parse(readCookie('access_token')).access_token
                },
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                // setFriendsList(data.friends);
				this.setState({
					friends: data.friends.length > 0 ? data.friends : [{ id: 0 }],
					initialFriends: ((data.friends || []).length) || 0,
				});
			this.setState({ conditionsAccepted : data?.preferences?.conditionsAccepted || this.state.conditionsAccepted });
			this.setState({isTransportRequired: data?.preferences?.isTransportRequired || this.state.isTransportRequired});
		    this.setState({sizeOfTee:data?.preferences?.sizeOfTee || this.state.sizeOfTee })

			if(data.friends.length > 0 ){
				this.setState({displayRegistrations :true})
			}
			else {
                this.setState({displayRegistrations :false})
			}

            }.bind(this))
    }

	render() {
		return (
			<Modal show={this.props.showReserveModal} onHide={this.props.closeReserveModal} className="square-modal" bsSize='lg'>
				<Modal.Header closeButton>
					<Modal.Title>RESERVE YOUR SPOT</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
					<ToastContainer
						position="bottom-center"
						autoClose={50000}
						hideProgressBar={true}
						closeOnClick={false}
						newestOnTop={false}
						pauseOnHover={true}
					/>
						<Col md={10} mdOffset={1} className="margin-col-30">
							<form name="groupForm">
								{this.props.volunteerOpportunity.config.isTransportRequired ? (
									<div className="form-group">
										<input type="checkbox" className="" id="need-transport"
											required 
											checked={this.state.isTransportRequired} 
											onChange={() => this.setState({ isTransportRequired: !this.state.isTransportRequired })} 
											
											/>
										<label className="checkbox-label" htmlFor="need-transport">I need transport</label>
									</div>
								) : (null)}

								{this.props.volunteerOpportunity.config.isTshirtProvided ? (
									<div className="form-group">
										<label className="control-label is-imp" style={{ fontWeight: 'bold' }}>Size of T-shirt:</label>
										<select className="form-control" required onChange={this.sizeOfTeeSelect} value={this.state.sizeOfTee}>
											<option selected disabled>Select one size</option>
											<option value="sm">XS</option>
											<option value="s">S</option>
											<option value="m">M</option>
											<option value="l">L</option>
											<option value="xl">XL</option>
											<option value="xxl">XXL</option>
										</select>
									</div>
								) : (null)}
								{
									// parseInt(this.props.volunteerOpportunity.registrationsAllowed)
								parseInt(this.state.allowedRegistrations - 1)	> 0 ?
									<div className="form-group mb-0">
										<input type="checkbox" className="" id="displayRegistrations" checked={this.state.displayRegistrations} required onChange={() => this.setState({ displayRegistrations: !this.state.displayRegistrations })} />
										<label className="checkbox-label ml5" htmlFor='displayRegistrations'> I want to bring my friend(s)/family member(s) to this event.</label>
										
										</div> : <></>
										// <note>We are sorry! There are no slots available to reserve for your friends/family members.</note>
								}
								{/* <div className="form-group mb-0">
									<input type="checkbox" className="" id="displayRegistrations" checked={this.state.displayRegistrations} required onChange={() => this.setState({ displayRegistrations: !this.state.displayRegistrations })} />
									<label className="checkbox-label ml5" htmlFor='displayRegistrations'> I want to bring my friend(s)/family member(s) to this event.</label>
								</div> */}

								{this.state.displayRegistrations && <>
									<note style={{fontWeight:700 }}>Note: Kindly do not add yourself as a guest.</note>

									<Row style={{ margin: 0 }}>
										<Col md={6} sm={6} xs={6} style={{ padding: 0 }}>
											<label className="control-label is-imp ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Name</label>
										</Col>
										<Col md={3} sm={6} xs={3} style={{ padding: 0 }}>
											<label className="control-label is-imp ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsGender' >Gender</label>
										</Col>
										<Col md={3} xs={3} sm={6} style={{ padding: 0 }}>
											<label className="control-label is-imp ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Age</label>
										</Col>
										{this.state.friends.map((friend, index) => <>
											<Col md={6} sm={6} xs={6} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0" >
													<input type="text" className="form-control" required value={friend.name} onChange={(e) => this.handleAddFriends(index, e.target.value, 'name')} />
												</div>
											</Col>

											<Col md={3} sm={3} xs={3} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0">
													<select className="form-control" required value={friend.gender} onChange={(e) => this.handleAddFriends(index, e.target.value, 'gender')}>
														<option selected disabled>Gender</option>
														<option value="Male">Male</option>
														<option value="Female">Female</option>
														<option value="Prefer not to say">Prefer not to say</option>
													</select>
												</div>
											</Col>
											<Col md={2} sm={2} xs={2} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0">
													<input
														type="number"
														className="form-control"
														required
														value={friend.age}
														max={80} min={5}
														onChange={(e) => (e.target.value > 4 && e.target.value < 81) && this.handleAddFriends(index, parseInt(e.target.value), 'age')}
													/>
												</div>
											</Col>
											<Col md={1} sm={1} xs={1} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0">
													{/* <button className='btn btn-primary' style={{ marginLeft: 5 }} onClick={() => this.deleterow(index,friend._id)}>
														<i className="fa fa-trash"></i>
													</button> */}
													<Button className='btn btn-primary' style={{ marginLeft: 5 }} onClick={() => this.deleterow(index,friend._id)}>
														<i className="fa fa-trash"></i>
													</Button>
												</div>
											</Col>
										</>)}
									</Row>
									{(this.props.volunteerOpportunity.isUserAttendee ? this.state.allowedRegistrations : this.state.allowedRegistrations - 1) > (this.state.friends.length - this.state.initialFriends) && <div align='right' className='mt10'>
										<button className={`btn ${this.friendsDetailsAdded() ? 'btn-primary' : 'btn-primary-disabled'} btn-sm`} onClick={this.addFriends}>
											<i className="fa fa-plus"></i> add
										</button>
									</div>}
								</>}

								<div className="form-group mt10">
									<input type="checkbox" className="" id="consent" required   checked={this.state.conditionsAccepted} onChange={() => this.setState({ conditionsAccepted: !this.state.conditionsAccepted })} />
									<label className="checkbox-label" htmlFor="consent">
										I hereby provide my confirmation that I shall be present at the venue. In case of any unforeseen events, I shall ensure that I will arrange for my replacement so that the NGO/quality of the activity is not impacted.
										{/* that I bring my replacement. */}
									</label>
								</div>
							</form>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					{this.state.conditionsAccepted && this.state.isResponse ? (  
					( this.state.isResponse && this.state.conditionsAccepted && this.state.displayRegistrations && this.friendsDetailsAdded() ? <Button onClick={this.submitApply} className="btn btn-primary">Reserve My Spot</Button> 
					: ( this.state.conditionsAccepted && this.state.isResponse && !this.friendsDetailsAdded()  && !this.state.displayRegistrations ? 
					<Button onClick={this.submitApply} className="btn btn-primary">Reserve My Spot</Button> : <Button disabled className="btn btn-primary">Reserve My Spot</Button>) ) 
					) : (	<Button disabled className="btn btn-primary">Reserve My Spot</Button> )}
					<Button onClick={this.props.closeReserveModal} className="btn-sq btn-cancel">Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
